<template>
  <v-container>
    <v-card class="mx-auto">
      <v-card-text>
        <div>{{ $t('How we compare monitored pages') }}</div>
        <h1 class="display-0 text--primary mb-4 mt-2">{{ $t('How we compare monitored pages') }}</h1>
        <v-card class="mx-auto" tile elevation="0" v-for="topic in topics" :key="topic.headline">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1"> {{ topic.headline }} </v-list-item-title>
              <v-list-item-subtitle>
                A {{ topic.valueInText }} is considered unique if those values are the same:
                <strong>{{ topic.definitionUnique.join(', ') }}</strong></v-list-item-subtitle
              >
              <v-list-item-subtitle>
                What we compare: <strong>{{ topic.whatWeCompare.join(', ') }}</strong>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ topic.description }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'ExplainHowCompare',
  data: () => ({
    topics: [
      {
        headline: 'Custom Script Result',
        valueInText: 'custom script result',
        definitionUnique: ['return value of the script'],
        whatWeCompare: ['return value of the script'],
        description: '',
        example: '',
      },
      {
        headline: 'Consent Banner found',
        valueInText: 'consent banner',
        definitionUnique: ['found: true/false'],
        whatWeCompare: ['found: true/false'],
        description: '',
        example: '',
      },
      {
        headline: 'Cookies',
        valueInText: 'cookie',
        definitionUnique: ['Name', 'Domain', 'Path'],
        whatWeCompare: ['Name', 'Domain', 'Path'],
        description: '',
        example: '',
      },
      {
        headline: 'localStorage',
        valueInText: 'localStorage',
        definitionUnique: ['Name'],
        whatWeCompare: ['Name'],
        description: '',
        example: '',
      },
      {
        headline: 'Tracking Request',
        valueInText: 'tracking request',
        definitionUnique: ['Name', 'Account Id'],
        whatWeCompare: ['Name', 'Account Id'],
        description:
          'What we do not compare is the number of "same" requests. So if there are in one result a request to Google Analytics with property id G-XXXXXX and in the other resulte three requests to the same property id, it will be considered as "no change".',
        example: '',
      },
      {
        headline: '3rd Party Request',
        valueInText: '3rd party request',
        definitionUnique: ['Hostname'],
        whatWeCompare: ['Hostname'],
        description:
          'What we do not compare is the number of "same" requests. So if there are in one result a request to hostname xyc.example.com and in the other result three requests to the same hostname, it will be considered as "no change".',
        example: '',
      },
      {
        headline: 'Ext. Links',
        valueInText: 'external links',
        definitionUnique: ['whole url'],
        whatWeCompare: ['whole url'],
        description: '',
        example: '',
      },
    ],
  }),
  metaInfo: {
    title: 'Free Cookie Scanner - how we compare.',
    decription:
      'You can monitor your page for changes and get alerted. Here we explain you how we compare the different results.',
  },
};
</script>
